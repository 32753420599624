import React, { useEffect, useState } from 'react'
import PageHeading from '../../../components/PageHeading'
import FilterTable from '../../../components/FilterTable'
import GetProjectExpense from '../../../APIs/AddExpense/GetProjectExpense'
import { Link, useNavigate, useParams } from 'react-router-dom'
import DeleteProjectExpense from '../../../APIs/AddExpense/DeleteProjectExpense'
import AddExpensesModal from '../AddExpensesModal'
import GetProjectExpenseByProject from '../../../APIs/AddExpense/GetProjectExpenseByProject'
import SendAmount from '../../Accounting/InvestorOverview/SendAmount'

export default function ShowAddExpenses(props) {
    let navigate = useNavigate()
    let [expenseList, setExpenseList] = useState([])
    let [expenseTempList, setExpenseTempList] = useState([])
    let [expensesModal, setExpensesModal] = useState(0)
    let [editData, setEditData] = useState(0)
    let [showModal, setShowModal] = useState(0)
    let [modalData, setModaldata] = useState(0)
    let id = useParams()
    let getData = async (id) => {
        let resp = await GetProjectExpenseByProject(id)
        if (resp.status === 1) {
            setExpenseList(resp.responseValue.expenseDetails)
            setExpenseTempList(resp.responseValue.expenseDetails)
            props.setBoxData({ paidAmount: resp.responseValue.paidAmount, pendingAmount: resp.responseValue.pendingAmount, totalExpense: resp.responseValue.totalExpense })
        }

    }

    let handleDelete = async (ids) => {
        let resp = await DeleteProjectExpense(ids)
        if (resp.status === 1) {
            getData(id.id)
        }
        else {

        }

    }
    let handleEdit = (val) => {
        setExpensesModal(1)
        setEditData(val)
    }

    let handleTransaction = (val) => {
        if (val.isNoVendor === false) {
            navigate(`/investorportfoliooverview/Expense/Vendor/${val.referenceId}/${val.paidToName}/${val.paidToId}/1/${val.projectName}`)
        }
        else {
            setModaldata(val)
            setShowModal(1)
        }

    }
    useEffect(() => {
        console.log("")
        if (props.expensesModal === 0) {
            getData(id.id)
        }
    }, [props.expensesModal])
    return (
        <>
            <div className='container-fluid landformconatiner p-4 mt-3'>
                <div className='d-flex flex-column flex-md-row justify-content-between'>
                    <PageHeading pagename="Expenses List" />
                    <FilterTable tempList={expenseTempList} tempListMethod={setExpenseTempList} originalList={expenseList} isSearch={true} isdate={false} isFilter={false} isExport={false} />
                </div>
                <div className='pt-4'>
                    <table class="table">
                        <thead>
                            <tr className='table-head'>
                                <th scope="col">S.No.</th>
                                {/* <th scope="col">Name</th> */}
                                <th scope="col">Date</th>
                                <th scope="col">Expenses</th>
                                <th scope="col">Expenses Type</th>
                                <th scope="col">Amount (&#8377;)</th>
                                <th scope="col">Payment Status</th>
                                <th scope="col">ACTION</th>

                            </tr>
                        </thead>
                        <tbody className='table-body'>
                            {
                                expenseTempList && expenseTempList.map((val, ind) => {
                                    return (
                                        <tr >
                                            <td scope="row">{ind + 1}</td>
                                            <td>{val.transactionDate}</td>
                                            <td>{val.expense}</td>
                                            <td>{val.expenseType}</td>
                                            <td>{val.amount}</td>
                                            <td>{val.paymentStatus}</td>
                                            {/* <td>{val.date}</td> */}
                                            <td>
                                                <div class="dropdown dropend">
                                                    {/* <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Dropdown button
                                                    </button> */}
                                                    <i class="bi bi-three-dots-vertical " data-bs-toggle="dropdown" aria-expanded="false"></i>
                                                    <ul className="dropdown-menu mr-3">
                                                        <li><span className="dropdown-item" onClick={() => { handleTransaction(val) }}>{val.isNoVendor === true?"Send Amount":"View Transaction"}</span></li>
                                                        <li><span className="dropdown-item" onClick={() => { handleEdit(val) }}>Edit</span></li>
                                                        <li><span className="dropdown-item" onClick={() => handleDelete(val.expenseId)}>Delete</span></li>
                                                    </ul>
                                                </div>
                                            </td>
                                            <td></td>
                                        </tr>
                                    )
                                })
                            }


                        </tbody>
                    </table>
                </div>


            </div>
            {
                expensesModal === 1 ? <AddExpensesModal showModal={expensesModal} closeModal={setExpensesModal} isEdit={1} editData={editData} /> : ""
            }

            {
                showModal === 1 ?
                    <SendAmount showModal={showModal} setShowModal={setShowModal} investmentType={"Expense"} payeeType={modalData.payeeType} investorName={modalData.paidToName} investorId={0} referenceId={modalData.referenceId} pendingAmount={modalData.pendingAmount} transactionType={1} />
                    : ""
            }

        </>
    )
}
