import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';

export default function Sidebar() {

    let [menuList, setMenuList] = useState([

        {
            menuName: "All Projects",
            url: "/allproject/",
            icon:"bi bi-building",
            subMenu: []
        },


        {
            menuName: "Master",
            url: "#",
            subMenu: [
                {
                    menuName: "Payment Master",
                    url: "/paymentmaster/",
                    icon:"bi bi-building",
                },
                {
                    menuName: "Expense Master",
                    url: "/expensemaster/",
                    icon:"bi bi-building",
                },


            ],
        },
        {
            menuName: "Investor",
            url: "/investorlist/",
            subMenu: [
                // {
                //     menuName: "Investor List",
                //     url: "/investorlist/",
                // },
                {
                    menuName: "Company",
                    url: "/showcompanyinvestor/",
                    icon:"bi bi-building ",
                },
                {
                    menuName: "Individual",
                    url: "/individualinvestor/",
                    icon:"bi bi-building",
                },


            ],
        },
        {
            menuName: "Vendor",
            url: "/vendorlist/",
            subMenu: [
                // {
                //     menuName: "Investor List",
                //     url: "/investorlist/",
                // },
                {
                    menuName: "Company",
                    url: "/showcompanyvendor/",
                    icon:"bi bi-building ",
                },
                {
                    menuName: "Individual",
                    url: "/individualvendor/",
                    icon:"bi bi-building",
                },


            ],
        },

        {
            menuName: "Account",
            url: "/accountdashboardadd/",
            subMenu: [
                // {
                //     menuName: "Account Dashnboard",
                //     url: "/accountdashboard/",
                //     icon:"bi bi-building",
                // },
                // {
                //     menuName: "Account Dashnboard",
                //     url: "/accountdashboardadd/",
                //     icon:"bi bi-building",
                // },
                {
                    menuName: "Sales Overview",
                    url: "/salesoverview/",
                    icon:"bi bi-building",
                },
                {
                    menuName: "Expenses Overview",
                    url: "/expensesoverview/",
                    icon:"bi bi-building",
                },
                // {
                //     menuName: "Investor Overview",
                //     url: "/investoroverview/",
                //     icon:"bi bi-building",
                // },
                {
                    menuName: "Investor Portfolio Overview",
                    url: "/investorportfolio/",
                    icon:"bi bi-building",
                },
                {
                    menuName: "Owner Portfolio Overview",
                    url: "/ownerportfolio/",
                    icon:"bi bi-building",
                },
                // {
                //     menuName: "Expenses Portfolio Overview",
                //     url: "#",
                //     icon:"bi bi-building",
                // },
                // {
                //     menuName: "Send Amount",
                //     url: "/sendamount/",
                //     icon:"bi bi-building",
                // },
                {
                    menuName: "Project & Plot Summery",
                    url: "/projectplotsummery/",
                    icon:"bi bi-building",
                },
                


            ],
        },

        {
            menuName: "Project Portfolio",
            url: "/projectportfolio/",
            subMenu: [],
        },



    ])
    let [onload, setOnload] = useState(0)
    let navigate = useNavigate()
    // let handleClick = (e, val, mulSub, ind) => {
    //     let menuList = document.getElementById("sidebar-ul").getElementsByTagName("li");
    //     for (const li of menuList) {
    //         li.classList.remove("selected");
    //     }
    //     e.currentTarget.classList.add("selected");
    //     setOnload(1)
    //     if (mulSub === 2) {
    //         if (document.getElementById("submenu" + ind).classList.contains("submenucontainer")) {
    //             document.getElementById("submenu" + ind).classList.remove("submenucontainer")
    //             document.getElementById("submenu" + ind).classList.add("submenucontainer2")
    //         }
    //         else {
    //             document.getElementById("submenu" + ind).classList.remove("submenucontainer2")
    //             document.getElementById("submenu" + ind).classList.add("submenucontainer")
    //         }
    //     }
    //     else {
    //         for (var i = 0; i <= menuList.length; i++) {
    //             if (i !== val) {
    //                 if (document.getElementById("submenu" + i)) {
    //                     console.log("s", document.getElementById("submenu" + i))
    //                     document.getElementById("submenu" + i).classList.remove("submenucontainer2")
    //                     document.getElementById("submenu" + i).classList.add("submenucontainer")
    //                 }

    //             }
    //         }
    //     }

    //     // if (val !== 0) {
    //     //     if (!document.getElementById("sidebar-li-ul" + val).classList.contains("selected")) {
    //     //         if(mulSub === 1)
    //     //         {
    //     //             document.getElementById("sidebar-li-ul" + val).classList.add("selected")

    //     //         }
    //     //         else{
    //     //             document.getElementById("sidebar-li-ul" + val).classList.add("selected")
    //     //         }
    //     //     }
    //     //     else {
    //     //         document.getElementById("sidebar-li-ul" + val).classList.remove("selected")

    //     //     }
    //     //     for (var i = 0; i <= menuList.length; i++) {
    //     //         if (i !== val) {
    //     //             if (document.getElementById("sidebar-li-ul" + i) !== null) {
    //     //                 let submenuList = document.getElementById("sidebar-li-ul" + i)
    //     //                 submenuList.classList.remove("selected")
    //     //                 submenuList.classList.remove("")
    //     //             }

    //     //         }
    //     //     }



    //     // }
    // }



    let [activeIndex, setActiveIndex] = useState(0)

    let handleNavbar = (e, index, close = true) => {
        if (document.getElementById("components" + index).classList.contains("show") && close) {

            // document.getElementById("link" + index).classList.add("collapsed")
            // document.getElementById("link" + index).classList.remove("collapse")

            // document.getElementById("components" + index).classList.remove("show")
            setActiveIndex(null)

        }
        else {

            // document.getElementById("link" + index).classList.remove("collapsed")
            // document.getElementById("link" + index).classList.add("collapse")

            // document.getElementById("components" + index).classList.add("show")
            setActiveIndex(index)
        }

        if (menuList.length !== 0) {
            for (var i = 0; i <= menuList.length - 1; i++) {
                if (i !== index) {
                    console.log("cdvdfvfdvs", index, i)
                    if (document.getElementById("components" + i)) {
                        document.getElementById("components" + i).classList.add("collapsed")
                        document.getElementById("components" + i).classList.remove("collapse")
                        document.getElementById("components" + i).classList.remove("show")
                    }
                }
            }
        }
    }

    return (
        // <div className='sidebar  pt-5 ' id="sidebar">
        //     <ul className='sidebar-ul' id="sidebar-ul">
        //         {menuList && menuList.map((val, ind) => {
        //             if (val.subMenu.length === 0) {
        //                 return (
        //                     <Link to={val.url}>
        //                         <li className={` nav-item d-flex flex-row gap-3 pointer  align-items-center ${onload === 0 && ind === 0 ? "selected" : ""}`} onClick={(e) => handleClick(e, 0, 1, ind)}>
        //                             <svg width="24" height="26" viewBox="0 0 24 26" fill="#fff" xmlns="http://www.w3.org/2000/svg">
        //                                 <path d="M0 14H2.66667V26H0V14ZM21.3333 8.66666H24V26H21.3333V8.66666ZM10.6667 0.666656H13.3333V26H10.6667V0.666656Z" fill="#737791" />
        //                             </svg>
        //                             <span className='sidebar-name'>
        //                                 {val.menuName}
        //                             </span>
        //                         </li>
        //                     </Link>
        //                 )

        //             }
        //             else {
        //                 return (
        //                     <li className={`nav-item d-flex flex-column pointer gap-2 sider-li-ul   ${onload === 0 && ind === 0 ? "selected" : ""}`} onClick={(e) => handleClick(e, 0, 2, ind)}>
        //                         <div className='d-flex flex-row'>
        //                             <Link className='d-flex flex-row gap-2 justify-content-center align-items-center' to={val.url}>
        //                                 <div className='avatar'>
        //                                     <svg width="24" height="26" viewBox="0 0 24 26" fill="#fff" xmlns="http://www.w3.org/2000/svg">
        //                                         <path d="M0 14H2.66667V26H0V14ZM21.3333 8.66666H24V26H21.3333V8.66666ZM10.6667 0.666656H13.3333V26H10.6667V0.666656Z" fill="#737791" />
        //                                     </svg>
        //                                 </div>
        //                                 <div className='d-flex flex-row justify-content-between align-items-center gap-5 pointer'>
        //                                     <div style={{ color: "#737791" }}>
        //                                         {val.menuName}
        //                                     </div>
        //                                     <sapn className="fa-solid fa-angle-down" style={{ color: "#737791" }}></sapn>
        //                                 </div>
        //                             </Link>
        //                         </div>

        //                         <ul className="submenucontainer_ nav-content d-flex flex-column gap-2 p-2" id={"submenu" + ind} >
        //                             {val.subMenu && val.subMenu.map((v, inds) => {
        //                                 return (
        //                                     <li className='nav-item'>
        //                                         <Link className="sidebarsubmenu" to={v.url} onClick={() => { navigate(v.url) }}>
        //                                             <i class="bi bi-building"></i>
        //                                             <span className=''>{v.menuName}</span>
        //                                         </Link>
        //                                     </li>
        //                                 )
        //                             })}
        //                         </ul>
        //                     </li>
        //                 )
        //             }
        //         })}



        //     </ul>
        // </div>




        <>
            <aside id="sidebar" class="sidebar">

                <ul class="sidebar-nav" id="sidebar-nav">
                    {
                        menuList && menuList.map((val, ind) => {
                            if (val.subMenu.length === 0 && ind === 0)   {
                                return (
                                    <li class="nav-item" onClick={()=>{setActiveIndex(0)}}>
                                        <Link class={`nav-link ${activeIndex === ind?"selected":""}`} id={"link" + ind} to={val.url} >
                                            <svg width="24" height="26" viewBox="0 0 24 26" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 14H2.66667V26H0V14ZM21.3333 8.66666H24V26H21.3333V8.66666ZM10.6667 0.666656H13.3333V26H10.6667V0.666656Z" fill="#737791" />
                                            </svg>
                                            <span className='nav-heading_'>{val.menuName}</span>
                                        </Link>
                                    </li>
                                )
                            }
                            else if(val.subMenu.length === 0){
                                return (
                                    <li class="nav-item" onClick={()=>{setActiveIndex(ind)}}>
                                        <Link class={`nav-link ${activeIndex === ind?"selected":""}`} id={"link" + ind} to={val.url} >
                                            <svg width="24" height="26" viewBox="0 0 24 26" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 14H2.66667V26H0V14ZM21.3333 8.66666H24V26H21.3333V8.66666ZM10.6667 0.666656H13.3333V26H10.6667V0.666656Z" fill="#737791" />
                                            </svg>
                                            <span className='nav-heading_'>{val.menuName}</span>
                                        </Link>
                                    </li>
                                )
                            }
                            else {
                                return (
                                    <>
                                        <li class="nav-item" onClick={(e) => { handleNavbar(e, ind) }}>
                                            <Link class={`nav-link ${activeIndex === ind ? "collapse selected" : "collapsed"} `} id={"link" + ind} to={val.url} >
                                                <svg width="24" height="26" viewBox="0 0 24 26" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 14H2.66667V26H0V14ZM21.3333 8.66666H24V26H21.3333V8.66666ZM10.6667 0.666656H13.3333V26H10.6667V0.666656Z" fill="#737791" />
                                                </svg>
                                                <span>{val.menuName}</span><i class="bi bi-chevron-down ms-auto"></i>
                                            </Link>
                                            <ul id={"components" + ind} class={`nav-content collapse ${activeIndex === ind ? "d-block show" : "d-none"}`} >
                                                {
                                                    val.subMenu.map((vv, index) => {
                                                        return (
                                                            <li onClick={(e)=>{handleNavbar(e, ind, false)}}>
                                                                <Link to={vv.url}>
                                                                    <i style={{fontSize:"14.7px"}} class={vv.icon}></i><span>{vv.menuName}</span>
                                                                </Link>
                                                            </li>
                                                        )
                                                    })
                                                }

                                            </ul>
                                        </li>
                                    </>
                                )
                            }
                        })
                    }




                </ul>

            </aside>
        </>
    )
}


